import React from "react";
import { Box, useTheme } from "@mui/material";
import { footerHeight } from "../../../shared/style/Style-MainTheme";
export function FooterLayoutComponent(_a) {
    var _b;
    var lastUpdateComponent = _a.lastUpdateComponent, linkListComponent = _a.linkListComponent;
    var theme = useTheme();
    return (React.createElement(Box, { component: "footer", sx: (_b = {
                position: "static",
                width: "100%",
                backgroundColor: theme.palette.background.paper,
                borderTop: "2px solid ".concat(theme.palette.primary.main),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: theme.spacing(1),
                boxSizing: "border-box"
            },
            _b[theme.breakpoints.up("md")] = {
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                height: "".concat(footerHeight, "px"),
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: theme.spacing(0, 2),
            },
            _b) },
        lastUpdateComponent,
        linkListComponent));
}
