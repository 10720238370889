var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DialogComponent, } from "../../shared/components/dialog/Dialog.component";
var tableTextStyle = {
    fontSize: "0.75rem",
    letterSpacing: "0",
    whiteSpace: "nowrap",
};
function createTableRowCells(row) {
    var e_1, _a;
    var tableCellList = [];
    tableCellList.push(React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", key: "amr-table-cell-".concat(row.amrSubstance, "-short") }, row.shortSubstance), React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", key: "amr-table-cell-".concat(row.amrSubstance, "-substanceClass") }, row.substanceClass), React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", key: "amr-table-cell-".concat(row.amrSubstance, "-wirkstoff") }, row.wirkstoff));
    var sortedYears = Object.keys(row.concentrationList).sort(function (a, b) { return parseInt(b) - parseInt(a); });
    try {
        for (var sortedYears_1 = __values(sortedYears), sortedYears_1_1 = sortedYears_1.next(); !sortedYears_1_1.done; sortedYears_1_1 = sortedYears_1.next()) {
            var year = sortedYears_1_1.value;
            var concentrationPerYear = row.concentrationList[year];
            tableCellList.push(React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", align: "right", key: "amr-table-cell-".concat(row.amrSubstance, "-").concat(year, "-max") }, concentrationPerYear.max), React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", align: "right", key: "amr-table-cell-".concat(row.amrSubstance, "-").concat(year, "-min") }, concentrationPerYear.min), React.createElement(TableCell, { sx: tableTextStyle, component: "td", scope: "row", align: "right", key: "amr-table-cell-".concat(row.amrSubstance, "-").concat(year, "-cutOff") }, concentrationPerYear.cutOff));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (sortedYears_1_1 && !sortedYears_1_1.done && (_a = sortedYears_1.return)) _a.call(sortedYears_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return tableCellList;
}
export function InfoPageAmrDialogComponent(props) {
    var e_2, _a;
    var t = useTranslation(["InfoPage"]).t;
    var handleClose = function () {
        props.onClose();
    };
    var handleSubmit = function () {
        props.onAmrDataExport();
    };
    var tableSubHeader = [];
    var _loop_1 = function (subHeaderKey) {
        props.resistancesTableData.tableSubHeader[subHeaderKey].forEach(function (subHeaderValue) {
            tableSubHeader.push(React.createElement(TableCell, { key: "subheader-amr-".concat(subHeaderKey, "-").concat(subHeaderValue), sx: tableTextStyle, component: "th", align: "right" }, subHeaderValue));
        });
    };
    try {
        for (var _b = __values(Object.keys(props.resistancesTableData.tableSubHeader)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var subHeaderKey = _c.value;
            _loop_1(subHeaderKey);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    var nrOfSubstanceHeaderCells = 3;
    var substanceTableHeader = [];
    var spacingCells = [];
    for (var i = 0; i < nrOfSubstanceHeaderCells; i += 1) {
        substanceTableHeader.push(React.createElement(TableCell, { key: "header-amr-".concat(props.resistancesTableData.tableHeader[i]), sx: tableTextStyle, component: "th", colSpan: 1 }, props.resistancesTableData.tableHeader[i]));
        spacingCells.push(React.createElement(TableCell, { key: "header-amr-emptyCell-class-".concat(i), sx: tableTextStyle, component: "th", align: "right" }, "\u00A0"));
    }
    var dialogTableContent = (React.createElement(TableContainer, { sx: {
            maxWidth: "100%",
            width: "auto",
            height: "inherit",
            overflowY: "auto",
        }, component: Paper },
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "amr-table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    substanceTableHeader,
                    props.resistancesTableData.tableHeader
                        .slice(nrOfSubstanceHeaderCells)
                        .map(function (headerValue) { return (React.createElement(TableCell, { key: "header-amr-".concat(headerValue), css: tableTextStyle, component: "th", colSpan: 3, align: "center" }, headerValue)); })),
                React.createElement(TableRow, null,
                    spacingCells,
                    tableSubHeader)),
            React.createElement(TableBody, null, props.resistancesTableData.tableRows.map(function (row) { return (React.createElement(TableRow, { key: "amr-table-row-".concat(row.amrSubstance) }, createTableRowCells(row))); })))));
    var amrTableCancelButton = {
        content: t("Methods.Amrs.CancelButton"),
        onClick: handleClose,
    };
    var amrTableSubmitButton = {
        content: t("Methods.Amrs.ExportButton"),
        onClick: handleSubmit,
    };
    return DialogComponent({
        loading: false,
        dialogTitle: props.resistancesTableData.title,
        dialogContentText: props.resistancesTableData.description,
        dialogContent: dialogTableContent,
        cancelButton: amrTableCancelButton,
        submitButton: amrTableSubmitButton,
    });
}
