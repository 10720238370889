var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { WELCOME } from "./../../shared/infrastructure/router/routes";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
function getTranslations(t) {
    var hardCodedSubtitle = t("Subtitle") || "Default Subtitle";
    var hardCodedContent = t("MainText") || "Default Main Text";
    return { hardCodedSubtitle: hardCodedSubtitle, hardCodedContent: hardCodedContent };
}
var useWelcomePageComponent = function () {
    var t = useTranslation(["HomePage"]).t;
    var _a = getTranslations(t), hardCodedSubtitle = _a.hardCodedSubtitle, hardCodedContent = _a.hardCodedContent;
    var location = useLocation();
    var history = useHistory();
    var _b = __read(useState(hardCodedSubtitle), 2), subtitle = _b[0], setSubtitle = _b[1];
    var _c = __read(useState(hardCodedContent), 2), content = _c[0], setContent = _c[1];
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        var localeParam = params.get("locale");
        if (localeParam && localeParam !== i18next.language) {
            i18next.changeLanguage(localeParam);
        }
    }, [location.search]);
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        if (params.get("locale") !== i18next.language) {
            params.set("locale", i18next.language);
            history.replace({ search: params.toString() });
        }
    }, [i18next.language, location.search, history]);
    useEffect(function () {
        var url = "".concat(WELCOME, "?locale=").concat(i18next.language);
        console.log("Fetching welcome page data from:", url);
        callApiService(url)
            .then(function (response) {
            console.log("Received API response:", response);
            if (response.data && response.data.data) {
                var _a = response.data.data, subheading = _a.subheading, apiContent = _a.content;
                setSubtitle(subheading || hardCodedSubtitle);
                setContent(apiContent || hardCodedContent);
            }
            else {
                console.warn("API response data is missing expected fields.");
            }
            return response;
        })
            .catch(function (error) {
            console.error("Error fetching welcome page data:", error);
        });
    }, [hardCodedContent, hardCodedSubtitle, t, i18next.language]);
    var title = "ZooNotify";
    return {
        model: {
            title: title,
            subtitle: subtitle,
            content: content,
        },
        operations: {},
    };
};
export { useWelcomePageComponent };
