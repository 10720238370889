import React from "react";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { footerHeight } from "./../../../shared/style/Style-MainTheme";
export function FooterLayoutComponent(props) {
    var _a;
    var theme = useTheme();
    return (React.createElement(Box, { component: "footer", sx: (_a = {
                width: "100%",
                height: "".concat(footerHeight, "px"),
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                backgroundColor: theme.palette.background.paper,
                borderTop: "2px solid ".concat(theme.palette.primary.main)
            },
            _a[theme.breakpoints.down("sm")] = {
                flexDirection: "column",
                height: "auto",
                alignItems: "center",
                padding: theme.spacing(1),
            },
            _a) },
        props.lastUpdateComponent,
        props.linkListComponent));
}
