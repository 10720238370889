import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { LogoComponent } from "./Logo.component";
export function LogoCardComponent(props) {
    return (React.createElement(Card, { sx: {
            backgroundColor: "#dbe4eb",
            color: "#000",
            padding: "1em",
            margin: "2.5em auto",
            width: "50%",
            "@media (max-width: 768px)": {
                width: "90%",
                margin: "1em auto",
                backgroundColor: "#fff",
                color: "#000",
            },
        } },
        React.createElement(CardContent, null,
            React.createElement(Typography, { variant: "h1", sx: {
                    marginBottom: "1rem",
                    fontSize: "3rem",
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "#000",
                } }, props.title),
            React.createElement(Typography, { sx: {
                    margin: "1em 0",
                    fontSize: "0.85rem",
                    textAlign: "center",
                    color: "#000",
                } }, props.subtitle),
            React.createElement("div", { style: {
                    fontSize: "0.85rem",
                    lineHeight: "1.6",
                    textAlign: "justify",
                    color: "#000",
                } }, props.text)),
        React.createElement(LogoComponent, null)));
}
