import { getFormattedDate } from "./utils";
var logoImage = new Image();
logoImage.src = "/assets/bfr_logo.png";
export var logoPlugin = {
    id: "logoPlugin",
    beforeInit: function (chart) {
        if (!logoImage.complete) {
            logoImage.onload = function () {
                chart.update();
            };
        }
    },
    beforeDraw: function (chart) {
        var ctx = chart.ctx;
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    },
    afterDraw: function (chart) {
        var _a, _b;
        if (logoImage.complete) {
            var ctx = chart.ctx;
            var extraPadding = 20;
            var logoWidth = 80;
            var logoHeight = 35;
            var logoX = chart.chartArea.left + 10;
            var logoY = chart.chartArea.bottom + extraPadding + 10;
            ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
            var generatedOnText = ((_b = (_a = chart.options.plugins) === null || _a === void 0 ? void 0 : _a.customTexts) === null || _b === void 0 ? void 0 : _b.generatedOn) || "Generated on";
            var dateText = "".concat(generatedOnText, ": ").concat(getFormattedDate());
            ctx.font = "10px Arial";
            ctx.fillStyle = "#000";
            ctx.textAlign = "right";
            ctx.fillText(dateText, chart.width - 10, chart.height - 5);
        }
    },
};
export var errorBarTooltipPlugin = {
    id: "customErrorBarsTooltip",
    afterEvent: function (chart, args) {
        var event = args.event;
        var tooltip = chart.tooltip;
        var mouseX = event.x;
        var mouseY = event.y;
        chart.data.datasets.forEach(function (dataset, datasetIndex) {
            var meta = chart.getDatasetMeta(datasetIndex);
            meta.data.forEach(function (bar, index) {
                var dataPoint = dataset.data[index];
                if (dataPoint && (dataPoint.ciMin || dataPoint.ciMax)) {
                    var xMin = chart.scales.x.getPixelForValue(dataPoint.ciMin);
                    var xMax = chart.scales.x.getPixelForValue(dataPoint.ciMax);
                    var y = bar.y;
                    if (mouseX >= xMin &&
                        mouseX <= xMax &&
                        Math.abs(mouseY - y) < 5) {
                        if (tooltip &&
                            typeof tooltip.setActiveElements === "function") {
                            tooltip.setActiveElements([
                                {
                                    datasetIndex: datasetIndex,
                                    index: index,
                                },
                            ], { x: mouseX, y: mouseY });
                        }
                    }
                }
            });
        });
    },
};
export var drawErrorBars = function (chart) {
    var ctx = chart.ctx;
    chart.data.datasets.forEach(function (dataset, i) {
        var meta = chart.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
            var dataPoint = dataset.data[index];
            if (dataPoint && (dataPoint.ciMin || dataPoint.ciMax)) {
                var xMin = chart.scales.x.getPixelForValue(dataPoint.ciMin);
                var xMax = chart.scales.x.getPixelForValue(dataPoint.ciMax);
                var y = bar.y;
                ctx.save();
                ctx.strokeStyle = "black";
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(xMin, y);
                ctx.lineTo(xMax, y);
                ctx.stroke();
                ctx.beginPath();
                ctx.moveTo(xMin, y - 5);
                ctx.lineTo(xMin, y + 5);
                ctx.stroke();
                ctx.beginPath();
                ctx.moveTo(xMax, y - 5);
                ctx.lineTo(xMax, y + 5);
                ctx.stroke();
                ctx.restore();
            }
        });
    });
};
