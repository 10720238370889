var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { List, ListItem, ListSubheader } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Markdown from "markdown-to-jsx";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { EXTERNAL_LINKS } from "../../shared/infrastructure/router/routes";
export function LinkPageLinkListComponent() {
    var t = useTranslation(["ExternLinks"]).t;
    var _a = __read(useState([]), 2), linkData = _a[0], setLinkData = _a[1];
    useEffect(function () {
        var apiEndpoint = "".concat(EXTERNAL_LINKS, "?locale=").concat(i18next.language);
        console.log("[LinkPageLinkList] Fetching from:", apiEndpoint);
        callApiService(apiEndpoint)
            .then(function (response) {
            var _a;
            console.log("[LinkPageLinkList] Raw response:", response);
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) {
                var extractedLinks = response.data.data.map(function (item) { return item.attributes; });
                console.log("[LinkPageLinkList] extractedLinks:", extractedLinks);
                setLinkData(extractedLinks);
            }
            else {
                console.warn("[LinkPageLinkList] No data in response");
            }
            return response;
        })
            .catch(function (err) {
            console.error("[LinkPageLinkList] Error fetching data:", err);
            throw err;
        });
    }, [i18next.language]);
    function groupByCategory(links) {
        return links.reduce(function (acc, link) {
            if (!acc[link.category]) {
                acc[link.category] = [];
            }
            acc[link.category].push(link);
            return acc;
        }, {});
    }
    var groupedLinks = groupByCategory(linkData);
    console.log("[LinkPageLinkList] groupedLinks:", groupedLinks);
    var categoryOrder = [
        "ONLINE_TOOLS",
        "REPORTS",
        "ORGANIZATION_AND_INSTITUTES",
        "LEGAL_REGULATION",
    ];
    return (React.createElement("div", null, categoryOrder.map(function (category) {
        var itemsForCategory = groupedLinks[category];
        if (!itemsForCategory || itemsForCategory.length === 0) {
            console.log("[LinkPageLinkList] No items for category: \"".concat(category, "\""));
            return null;
        }
        var categoryLabel = t(category, { defaultValue: category });
        console.log("[LinkPageLinkList] t(".concat(category, "):"), categoryLabel);
        return (React.createElement(List, { key: category },
            React.createElement(ListSubheader, null, categoryLabel),
            itemsForCategory.map(function (item, idx) {
                console.log("[LinkPageLinkList] Rendering item #".concat(idx), item);
                return (React.createElement(ListItem, { key: idx },
                    React.createElement(Markdown, null, item.content)));
            })));
    })));
}
