var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { pageRoute } from "../../infrastructure/router/routes";
import { headerHeight, onPrimaryColor, onSecondaryColor, primaryColor, secondaryColor, } from "../../style/Style-MainTheme";
import { TranslationButtonsComponent } from "./TranslationButtons.component";
var headerStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    height: ", "px;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n"], ["\n    width: 100%;\n    height: ", "px;\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n"])), headerHeight);
var mainHeaderStyle = function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    box-sizing: border-box;\n    background-color: ", ";\n    color: ", ";\n"], ["\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    box-sizing: border-box;\n    background-color: ", ";\n    color: ", ";\n"])), primaryColor, onSecondaryColor); };
var leftHeaderStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-left: 1em;\n"], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-left: 1em;\n"])));
var appNameStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 1.2rem;\n    text-decoration: none;\n    color: ", ";\n    margin-right: 1em; /* \u2705 Added spacing between text and flags */\n\n    &:focus {\n        outline: none;\n    }\n"], ["\n    font-size: 1.2rem;\n    text-decoration: none;\n    color: ", ";\n    margin-right: 1em; /* \u2705 Added spacing between text and flags */\n\n    &:focus {\n        outline: none;\n    }\n"])), onPrimaryColor);
var rightHeaderStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    height: 100%;\n    margin-right: 8em;\n    display: flex;\n    align-items: flex-end;\n\n    @media (max-width: 768px) {\n        display: none; /* Hide desktop menu on mobile */\n    }\n"], ["\n    height: 100%;\n    margin-right: 8em;\n    display: flex;\n    align-items: flex-end;\n\n    @media (max-width: 768px) {\n        display: none; /* Hide desktop menu on mobile */\n    }\n"])));
var hamburgerButtonStyle = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: none;\n    background: none;\n    border: none;\n    color: ", ";\n    font-size: 1.5rem;\n    cursor: pointer;\n    margin-right: 1em;\n\n    @media (max-width: 768px) {\n        display: block;\n    }\n"], ["\n    display: none;\n    background: none;\n    border: none;\n    color: ", ";\n    font-size: 1.5rem;\n    cursor: pointer;\n    margin-right: 1em;\n\n    @media (max-width: 768px) {\n        display: block;\n    }\n"])), onPrimaryColor);
var mobileNavStyle = function (isOpen) { return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: ", ";\n    flex-direction: column;\n    align-items: flex-start;\n    background-color: #dbe4eb; /* \u2705 Your preferred color */\n    color: #000;\n    position: absolute;\n    top: ", "px;\n    left: 0;\n    width: 100%;\n    padding: 0;\n    z-index: 999;\n    border: 2px solid #000; /* \u2705 Full outer border */\n\n    @media (min-width: 769px) {\n        display: none; /* Hide mobile menu on desktop */\n    }\n"], ["\n    display: ", ";\n    flex-direction: column;\n    align-items: flex-start;\n    background-color: #dbe4eb; /* \u2705 Your preferred color */\n    color: #000;\n    position: absolute;\n    top: ", "px;\n    left: 0;\n    width: 100%;\n    padding: 0;\n    z-index: 999;\n    border: 2px solid #000; /* \u2705 Full outer border */\n\n    @media (min-width: 769px) {\n        display: none; /* Hide mobile menu on desktop */\n    }\n"])), isOpen ? "flex" : "none", headerHeight); };
var mobileNavLinkStyle = function (open) { return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    padding: 0.75em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: #000;\n    border: 1px solid #000; /* \u2705 Full border around each item */\n    width: 100%;\n\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        background-color: rgba(0, 0, 0, 0.1); /* \u2705 Subtle hover effect */\n    }\n"], ["\n    padding: 0.75em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: #000;\n    border: 1px solid #000; /* \u2705 Full border around each item */\n    width: 100%;\n\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        background-color: rgba(0, 0, 0, 0.1); /* \u2705 Subtle hover effect */\n    }\n"])), open ? secondaryColor : "transparent"); };
var navLinkStyle = function (open) { return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    padding: 0.5em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        color: ", ";\n    }\n"], ["\n    padding: 0.5em 1em;\n    font-size: 1rem;\n    text-decoration: none;\n    background-color: ", ";\n    color: ", ";\n\n    &:focus {\n        outline: none;\n    }\n    &:hover {\n        color: ", ";\n    }\n"])), open ? "".concat(secondaryColor) : "none", open ? "".concat(onSecondaryColor) : "".concat(onPrimaryColor), open ? "".concat(onSecondaryColor) : "".concat(secondaryColor)); };
export function HeaderComponent() {
    var _a = __read(useState(false), 2), isMenuOpen = _a[0], setIsMenuOpen = _a[1];
    var _b = __read(useState(false), 2), linkOpen = _b[0], setLinkOpen = _b[1];
    var _c = __read(useState(false), 2), linkedDataOpen = _c[0], setLinkedDataOpen = _c[1];
    var _d = __read(useState(false), 2), infoOpen = _d[0], setInfoOpen = _d[1];
    var _e = __read(useState(false), 2), evaluationsOpen = _e[0], setEvaluationsOpen = _e[1];
    var _f = __read(useState(false), 2), prevalenceOpen = _f[0], setPrevalenceOpen = _f[1];
    var t = useTranslation(["Header"]).t;
    var showLD = process.env.REACT_APP_SHOW_LD === "true";
    var pathname = useLocation().pathname;
    useEffect(function () {
        setInfoOpen(pathname === pageRoute.infoPagePath);
        setLinkOpen(pathname === pageRoute.linkPagePath);
        setEvaluationsOpen(pathname === pageRoute.evaluationsPagePath);
        setPrevalenceOpen(pathname === pageRoute.prevalencePagePath);
        setLinkedDataOpen(pathname === pageRoute.linkedDataPagePath);
        setIsMenuOpen(false);
    }, [pathname]);
    return (_jsxs("header", __assign({ css: headerStyle }, { children: [_jsxs("div", __assign({ css: mainHeaderStyle() }, { children: [_jsxs("div", __assign({ css: leftHeaderStyle }, { children: [_jsx(NavLink, __assign({ to: pageRoute.homePagePath, css: appNameStyle }, { children: "ZooNotify" })), _jsx(TranslationButtonsComponent, {})] })), _jsxs("div", __assign({ css: rightHeaderStyle }, { children: [_jsx(NavLink, __assign({ to: pageRoute.infoPagePath, css: navLinkStyle(infoOpen) }, { children: t("Info") })), _jsx(NavLink, __assign({ to: pageRoute.evaluationsPagePath, css: navLinkStyle(evaluationsOpen) }, { children: t("Evaluations") })), _jsx(NavLink, __assign({ to: pageRoute.prevalencePagePath, css: navLinkStyle(prevalenceOpen) }, { children: t("Prevalence") })), _jsx(NavLink, __assign({ to: pageRoute.linkPagePath, css: navLinkStyle(linkOpen) }, { children: t("Links") })), showLD && (_jsx(NavLink, __assign({ to: pageRoute.linkedDataPagePath, css: navLinkStyle(linkedDataOpen) }, { children: "LD" })))] })), _jsx("button", __assign({ css: hamburgerButtonStyle, onClick: function () { return setIsMenuOpen(!isMenuOpen); }, "aria-label": "Toggle Menu" }, { children: "\u2630" }))] })), _jsxs("div", __assign({ css: mobileNavStyle(isMenuOpen) }, { children: [_jsx(NavLink, __assign({ to: pageRoute.infoPagePath, css: mobileNavLinkStyle(infoOpen) }, { children: t("Info") })), _jsx(NavLink, __assign({ to: pageRoute.evaluationsPagePath, css: mobileNavLinkStyle(evaluationsOpen) }, { children: t("Evaluations") })), _jsx(NavLink, __assign({ to: pageRoute.prevalencePagePath, css: mobileNavLinkStyle(prevalenceOpen) }, { children: t("Prevalence") })), _jsx(NavLink, __assign({ to: pageRoute.linkPagePath, css: mobileNavLinkStyle(linkOpen) }, { children: t("Links") })), showLD && (_jsx(NavLink, __assign({ to: pageRoute.linkedDataPagePath, css: mobileNavLinkStyle(linkedDataOpen) }, { children: "LD" })))] }))] })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
