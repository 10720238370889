var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useContext, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { CMS_BASE_ENDPOINT, EVALUATIONS, } from "../../shared/infrastructure/router/routes";
import { MAX_PAGE_SIZE } from "../../shared/model/CMS.model";
import { initialFilterSelection } from "../model/constants";
export var DefaultEvaluationDataContext = createContext({
    isLoading: true,
    evaluationsData: {
        FUTTERMITTEL: [],
        TIERE: [],
        LEBENSMITTEL: [],
        MULTIPLE: [],
    },
    updateFilters: function () { },
    showDivision: function () { return true; },
});
export var useEvaluationData = function () {
    var context = useContext(DefaultEvaluationDataContext);
    if (context === undefined) {
        throw new Error("useEvaluationData must be used within an EvaluationDataProvider");
    }
    return context;
};
function isCompleteEntry(entry) {
    var _a, _b, _c, _d, _e;
    return (Boolean((_a = entry.title) === null || _a === void 0 ? void 0 : _a.trim()) &&
        Boolean((_b = entry.description) === null || _b === void 0 ? void 0 : _b.trim()) &&
        Boolean((_c = entry.category) === null || _c === void 0 ? void 0 : _c.trim()) &&
        Boolean((_d = entry.chartPath) === null || _d === void 0 ? void 0 : _d.trim()) &&
        Boolean((_e = entry.dataPath) === null || _e === void 0 ? void 0 : _e.trim()));
}
function parseQueryParams(queryParams) {
    var filters = __assign({}, initialFilterSelection);
    Object.keys(filters).forEach(function (key) {
        var param = queryParams.get(key);
        if (param) {
            filters[key] = param.split(",");
        }
    });
    return filters;
}
function buildQueryString(filters) {
    var params = new URLSearchParams();
    Object.entries(filters).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], arr = _b[1];
        if (arr.length) {
            params.set(key, arr.join(","));
        }
    });
    return params.toString();
}
function applyFiltersStrict(filterSelection, data) {
    var filteredData = {
        FUTTERMITTEL: [],
        TIERE: [],
        LEBENSMITTEL: [],
        MULTIPLE: [],
    };
    Object.keys(data).forEach(function (divisionKey) {
        filteredData[divisionKey] = data[divisionKey].filter(function (item) {
            return Object.keys(filterSelection).every(function (filterKey) {
                var selectedVals = filterSelection[filterKey];
                if (selectedVals.length === 0)
                    return true;
                if (item[filterKey] == null)
                    return false;
                return selectedVals.includes(item[filterKey]);
            });
        });
    });
    return filteredData;
}
function processApiResponse(apiData) {
    var result = {
        FUTTERMITTEL: [],
        TIERE: [],
        LEBENSMITTEL: [],
        MULTIPLE: [],
    };
    apiData.forEach(function (item) {
        var _a, _b;
        var divisionKey = item.division.toUpperCase();
        if (!result[divisionKey]) {
            return;
        }
        var chartPath = ((_a = item.diagram) === null || _a === void 0 ? void 0 : _a.url)
            ? CMS_BASE_ENDPOINT + item.diagram.url
            : "";
        var dataPath = ((_b = item.csv_data) === null || _b === void 0 ? void 0 : _b.url)
            ? CMS_BASE_ENDPOINT + item.csv_data.url
            : "";
        var newEntry = {
            id: item.id.toString(),
            title: item.title,
            description: item.description,
            category: item.category,
            division: divisionKey,
            microorganism: item.microorganism,
            diagramType: item.diagramType,
            productionType: item.productionType,
            matrix: item.matrix,
            chartPath: chartPath,
            dataPath: dataPath,
        };
        if (isCompleteEntry(newEntry)) {
            result[divisionKey].push(newEntry);
        }
    });
    return result;
}
export var EvaluationDataProvider = function (_a) {
    var children = _a.children;
    var i18n = useTranslation(["ExplanationPage"]).i18n;
    var _b = __read(useState(initialFilterSelection), 2), selectedFilters = _b[0], setSelectedFilters = _b[1];
    var _c = __read(useState({
        FUTTERMITTEL: [],
        TIERE: [],
        LEBENSMITTEL: [],
        MULTIPLE: [],
    }), 2), originalData = _c[0], setOriginalData = _c[1];
    var _d = __read(useState({
        FUTTERMITTEL: [],
        TIERE: [],
        LEBENSMITTEL: [],
        MULTIPLE: [],
    }), 2), evaluationsData = _d[0], setEvaluationsData = _d[1];
    var _e = __read(useState(true), 2), isLoading = _e[0], setLoading = _e[1];
    useEffect(function () {
        var fetchDataFromAPI = function () { return __awaiter(void 0, void 0, void 0, function () {
            var url, response, processedData, urlFilters, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        url = "".concat(EVALUATIONS, "?locale=").concat(i18n.language, "&populate=diagram&populate=csv_data&pagination[pageSize]=").concat(MAX_PAGE_SIZE);
                        return [4, callApiService(url)];
                    case 2:
                        response = _a.sent();
                        if (response.data) {
                            processedData = processApiResponse(response.data.data);
                            setOriginalData(processedData);
                            urlFilters = parseQueryParams(new URLSearchParams(window.location.search));
                            if (Object.values(urlFilters).some(function (arr) { return arr.length > 0; })) {
                                setSelectedFilters(urlFilters);
                                setEvaluationsData(applyFiltersStrict(urlFilters, processedData));
                            }
                            else {
                                setEvaluationsData(processedData);
                            }
                        }
                        return [3, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Fetching evaluation data failed", error_1);
                        return [3, 4];
                    case 4:
                        setLoading(false);
                        return [2];
                }
            });
        }); };
        void fetchDataFromAPI();
    }, [i18n.language]);
    useEffect(function () {
        if (!originalData.FUTTERMITTEL.length &&
            !originalData.TIERE.length &&
            !originalData.LEBENSMITTEL.length &&
            !originalData.MULTIPLE.length) {
            return;
        }
        var newData = applyFiltersStrict(selectedFilters, originalData);
        setEvaluationsData(newData);
        var params = buildQueryString(selectedFilters);
        var newUrl = "".concat(window.location.pathname).concat(params ? "?".concat(params) : "");
        if (newUrl !== window.location.href) {
            window.history.replaceState({}, "", newUrl);
        }
    }, [selectedFilters, originalData]);
    var updateFilters = function (newFilters) {
        setSelectedFilters(newFilters);
    };
    var showDivision = function (div) {
        if (selectedFilters.division.length === 0)
            return true;
        return selectedFilters.division.includes(div);
    };
    var value = {
        isLoading: isLoading,
        evaluationsData: evaluationsData,
        updateFilters: updateFilters,
        showDivision: showDivision,
    };
    return (React.createElement(DefaultEvaluationDataContext.Provider, { value: value }, children));
};
