var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import i18next from "i18next";
import React, { createContext, useContext, useState, useEffect, } from "react";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import { MICROORGANISMS, SAMPLE_ORIGINS, MATRICES, SAMPLING_STAGES, MATRIX_GROUPS, SUPER_CATEGORY_SAMPLE_ORIGINS, PREVALENCES, } from "../../shared/infrastructure/router/routes";
import { MAX_PAGE_SIZE, } from "../../shared/model/CMS.model";
var DefaultPrevalenceDataContext = createContext(undefined);
export var usePrevalenceFilters = function () {
    var context = useContext(DefaultPrevalenceDataContext);
    if (!context) {
        throw new Error("usePrevalenceFilters must be used within a PrevalenceDataProvider");
    }
    return context;
};
function processApiResponse(apiData, setApiError) {
    var validEntries = [];
    var errors = [];
    apiData.forEach(function (item) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        try {
            var entry = {
                id: item.id,
                samplingYear: item.attributes.samplingYear,
                numberOfSamples: item.attributes.numberOfSamples,
                numberOfPositive: (_a = item.attributes.numberOfPositive) !== null && _a !== void 0 ? _a : 0,
                percentageOfPositive: (_b = item.attributes.percentageOfPositive) !== null && _b !== void 0 ? _b : 0,
                ciMin: (_c = item.attributes.ciMin) !== null && _c !== void 0 ? _c : 0,
                ciMax: (_d = item.attributes.ciMax) !== null && _d !== void 0 ? _d : 0,
                matrix: item.attributes.matrix.data.attributes.name,
                matrixGroup: (_f = (_e = item.attributes.matrixGroup) === null || _e === void 0 ? void 0 : _e.data.attributes.name) !== null && _f !== void 0 ? _f : "",
                microorganism: item.attributes.microorganism.data.attributes.name,
                samplingStage: item.attributes.samplingStage.data.attributes.name,
                sampleOrigin: item.attributes.sampleOrigin.data.attributes.name,
                superCategorySampleOrigin: (_h = (_g = item.attributes.superCategorySampleOrigin) === null || _g === void 0 ? void 0 : _g.data.attributes.name) !== null && _h !== void 0 ? _h : "",
            };
            validEntries.push(entry);
        }
        catch (err) {
            errors.push("Error processing entry ID ".concat(item.id, ": ").concat(err));
        }
    });
    if (errors.length > 0) {
        setApiError(errors.join(", "));
    }
    return validEntries;
}
export var PrevalenceDataProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState([]), 2), selectedMicroorganisms = _b[0], setSelectedMicroorganisms = _b[1];
    var _c = __read(useState([]), 2), selectedSampleOrigins = _c[0], setSelectedSampleOrigins = _c[1];
    var _d = __read(useState([]), 2), selectedMatrices = _d[0], setSelectedMatrices = _d[1];
    var _e = __read(useState([]), 2), selectedSamplingStages = _e[0], setSelectedSamplingStages = _e[1];
    var _f = __read(useState([]), 2), selectedMatrixGroups = _f[0], setSelectedMatrixGroups = _f[1];
    var _g = __read(useState([]), 2), selectedYear = _g[0], setSelectedYear = _g[1];
    var _h = __read(useState([]), 2), selectedSuperCategory = _h[0], setSelectedSuperCategory = _h[1];
    var _j = __read(useState([]), 2), prevalenceData = _j[0], setPrevalenceData = _j[1];
    var _k = __read(useState([]), 2), fullPrevalenceData = _k[0], setFullPrevalenceData = _k[1];
    var _l = __read(useState({}), 2), searchParameters = _l[0], setSearchParameters = _l[1];
    var _m = __read(useState([]), 2), microorganismOptions = _m[0], setMicroorganismOptions = _m[1];
    var _o = __read(useState([]), 2), sampleOriginOptions = _o[0], setSampleOriginOptions = _o[1];
    var _p = __read(useState([]), 2), matrixOptions = _p[0], setMatrixOptions = _p[1];
    var _q = __read(useState([]), 2), samplingStageOptions = _q[0], setSamplingStageOptions = _q[1];
    var _r = __read(useState([]), 2), matrixGroupOptions = _r[0], setMatrixGroupOptions = _r[1];
    var _s = __read(useState([]), 2), superCategorySampleOriginOptions = _s[0], setSuperCategorySampleOriginOptions = _s[1];
    var _t = __read(useState(null), 2), error = _t[0], setError = _t[1];
    var _u = __read(useState(false), 2), loading = _u[0], setLoading = _u[1];
    var _v = __read(useState([]), 2), yearOptions = _v[0], setYearOptions = _v[1];
    var _w = __read(useState(false), 2), isSearchTriggered = _w[0], setIsSearchTriggered = _w[1];
    var _x = __read(useState(false), 2), showError = _x[0], setShowError = _x[1];
    var fetchPrevalenceData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var relationsToPopulate, populateParams, url, response, processedData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    relationsToPopulate = [
                        "matrix",
                        "microorganism",
                        "samplingStage",
                        "matrixGroup",
                        "sampleOrigin",
                        "superCategorySampleOrigin",
                    ];
                    populateParams = relationsToPopulate
                        .map(function (relation) {
                        return "populate[".concat(relation, "][locale]=").concat(i18next.language);
                    })
                        .join("&");
                    url = "".concat(PREVALENCES, "?locale=").concat(i18next.language, "&").concat(populateParams, "&pagination[pageSize]=").concat(MAX_PAGE_SIZE);
                    console.log("Fetching prevalence data from:", url);
                    return [4, callApiService(url)];
                case 1:
                    response = _a.sent();
                    console.log("Response data:", response.data);
                    if (response.data && response.data.data) {
                        processedData = processApiResponse(response.data.data, setError);
                        console.log("Processed Data:", processedData);
                        setFullPrevalenceData(processedData);
                        setPrevalenceData(processedData);
                    }
                    return [3, 3];
                case 2:
                    err_1 = _a.sent();
                    console.error("Error fetching prevalence data:", err_1);
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    var fetchOptions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchOption, _a, microorganisms, sampleOrigins, matrices, samplingStages, matrixGroups, superCategories, err_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    fetchOption = function (endpoint) { return __awaiter(void 0, void 0, void 0, function () {
                        var response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, callApiService("".concat(endpoint, "?locale=").concat(i18next.language))];
                                case 1:
                                    response = _a.sent();
                                    if (response.data && Array.isArray(response.data.data)) {
                                        return [2, response.data.data.map(function (item) { return ({
                                                name: item.attributes.name,
                                            }); })];
                                    }
                                    return [2, []];
                            }
                        });
                    }); };
                    return [4, Promise.all([
                            fetchOption(MICROORGANISMS),
                            fetchOption(SAMPLE_ORIGINS),
                            fetchOption(MATRICES),
                            fetchOption(SAMPLING_STAGES),
                            fetchOption(MATRIX_GROUPS),
                            fetchOption(SUPER_CATEGORY_SAMPLE_ORIGINS),
                        ])];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 6]), microorganisms = _a[0], sampleOrigins = _a[1], matrices = _a[2], samplingStages = _a[3], matrixGroups = _a[4], superCategories = _a[5];
                    setMicroorganismOptions(microorganisms);
                    setSampleOriginOptions(sampleOrigins);
                    setMatrixOptions(matrices);
                    setSamplingStageOptions(samplingStages);
                    setMatrixGroupOptions(matrixGroups);
                    setSuperCategorySampleOriginOptions(superCategories);
                    return [3, 3];
                case 2:
                    err_2 = _b.sent();
                    setError("Failed to fetch options: ".concat(err_2 instanceof Error ? err_2.message : "Unknown error"));
                    console.error(err_2);
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    var fetchDataFromAPI = function (selectedFilters) { return __awaiter(void 0, void 0, void 0, function () {
        var microorganisms, sampleOrigins, matrices, samplingStages, matrixGroups, years, superCategories, anySelectionMade, query, filters_1, addRelationalFilter, addSimpleFilter, relationsToPopulate, populateParams, response, processedData, searchParams_1, _loop_1, _a, _b, _c, key, values, searchString, err_3, fetchError;
        var e_1, _d;
        var _e, _f, _g, _h, _j, _k, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    setLoading(true);
                    microorganisms = (_e = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.microorganisms) !== null && _e !== void 0 ? _e : selectedMicroorganisms;
                    sampleOrigins = (_f = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.sampleOrigins) !== null && _f !== void 0 ? _f : selectedSampleOrigins;
                    matrices = (_g = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.matrices) !== null && _g !== void 0 ? _g : selectedMatrices;
                    samplingStages = (_h = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.samplingStages) !== null && _h !== void 0 ? _h : selectedSamplingStages;
                    matrixGroups = (_j = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.matrixGroups) !== null && _j !== void 0 ? _j : selectedMatrixGroups;
                    years = (_k = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.years) !== null && _k !== void 0 ? _k : selectedYear;
                    superCategories = (_l = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.superCategories) !== null && _l !== void 0 ? _l : selectedSuperCategory;
                    anySelectionMade = microorganisms.length > 0 ||
                        sampleOrigins.length > 0 ||
                        matrices.length > 0 ||
                        samplingStages.length > 0 ||
                        matrixGroups.length > 0 ||
                        superCategories.length > 0 ||
                        years.length > 0;
                    if (!anySelectionMade) {
                        setError("Please select at least one filter option.");
                        setShowError(true);
                        setLoading(false);
                        return [2];
                    }
                    _m.label = 1;
                case 1:
                    _m.trys.push([1, 3, 4, 5]);
                    query = "".concat(PREVALENCES, "?locale=").concat(i18next.language, "&pagination[pageSize]=").concat(MAX_PAGE_SIZE);
                    filters_1 = [];
                    addRelationalFilter = function (field, values) {
                        if (values.length > 0) {
                            filters_1.push(values
                                .map(function (value) {
                                return "filters[".concat(field, "][name][$eq]=").concat(encodeURIComponent(value));
                            })
                                .join("&"));
                        }
                    };
                    addSimpleFilter = function (field, values) {
                        if (values.length > 0) {
                            filters_1.push(values
                                .map(function (value) {
                                return "filters[".concat(field, "][$eq]=").concat(encodeURIComponent(value));
                            })
                                .join("&"));
                        }
                    };
                    addRelationalFilter("microorganism", microorganisms);
                    addRelationalFilter("sampleOrigin", sampleOrigins);
                    addRelationalFilter("matrix", matrices);
                    addRelationalFilter("samplingStage", samplingStages);
                    addRelationalFilter("matrixGroup", matrixGroups);
                    addRelationalFilter("superCategorySampleOrigin", superCategories);
                    addSimpleFilter("samplingYear", years);
                    if (filters_1.length > 0) {
                        query += "&" + filters_1.join("&");
                    }
                    relationsToPopulate = [
                        "matrix",
                        "microorganism",
                        "samplingStage",
                        "matrixGroup",
                        "sampleOrigin",
                        "superCategorySampleOrigin",
                    ];
                    populateParams = relationsToPopulate
                        .map(function (relation) {
                        return "populate[".concat(relation, "][locale]=").concat(i18next.language);
                    })
                        .join("&");
                    query += "&".concat(populateParams);
                    return [4, callApiService(query)];
                case 2:
                    response = _m.sent();
                    if (response.data && response.data.data) {
                        processedData = processApiResponse(response.data.data, setError);
                        setPrevalenceData(processedData);
                        setSearchParameters({
                            microorganism: microorganisms,
                            sampleOrigin: sampleOrigins,
                            matrix: matrices,
                            samplingStage: samplingStages,
                            matrixGroup: matrixGroups,
                            samplingYear: years.map(String),
                            superCategorySampleOrigin: superCategories,
                        });
                        setIsSearchTriggered(true);
                        searchParams_1 = new URLSearchParams();
                        _loop_1 = function (key, values) {
                            values.forEach(function (value) { return searchParams_1.append(key, value); });
                        };
                        try {
                            for (_a = __values(Object.entries({
                                microorganism: microorganisms,
                                sampleOrigin: sampleOrigins,
                                matrix: matrices,
                                samplingStage: samplingStages,
                                matrixGroup: matrixGroups,
                                samplingYear: years.map(String),
                                superCategorySampleOrigin: superCategories,
                            })), _b = _a.next(); !_b.done; _b = _a.next()) {
                                _c = __read(_b.value, 2), key = _c[0], values = _c[1];
                                _loop_1(key, values);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        searchString = searchParams_1.toString();
                        if (searchString) {
                            window.history.replaceState(null, "", "?".concat(searchString));
                        }
                        else {
                            window.history.replaceState(null, "", window.location.pathname);
                        }
                    }
                    return [3, 5];
                case 3:
                    err_3 = _m.sent();
                    fetchError = err_3;
                    setError("Failed to fetch data: ".concat(fetchError.message));
                    console.error(err_3);
                    return [3, 5];
                case 4:
                    setLoading(false);
                    return [7];
                case 5: return [2];
            }
        });
    }); };
    useEffect(function () {
        var initializeData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var urlSearchParams, params_1, initialSelectedMicroorganisms, initialSelectedSampleOrigins, initialSelectedMatrices, initialSelectedSamplingStages, initialSelectedMatrixGroups, initialSelectedYear, initialSelectedSuperCategory, anyParamsPresent, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        return [4, Promise.all([fetchOptions(), fetchPrevalenceData()])];
                    case 2:
                        _a.sent();
                        urlSearchParams = new URLSearchParams(window.location.search);
                        params_1 = {};
                        urlSearchParams.forEach(function (value, key) {
                            if (!params_1[key]) {
                                params_1[key] = [];
                            }
                            params_1[key].push(value);
                        });
                        initialSelectedMicroorganisms = params_1.microorganism || [];
                        initialSelectedSampleOrigins = params_1.sampleOrigin || [];
                        initialSelectedMatrices = params_1.matrix || [];
                        initialSelectedSamplingStages = params_1.samplingStage || [];
                        initialSelectedMatrixGroups = params_1.matrixGroup || [];
                        initialSelectedYear = params_1.samplingYear
                            ? params_1.samplingYear.map(Number)
                            : [];
                        initialSelectedSuperCategory = params_1.superCategorySampleOrigin || [];
                        setSelectedMicroorganisms(initialSelectedMicroorganisms);
                        setSelectedSampleOrigins(initialSelectedSampleOrigins);
                        setSelectedMatrices(initialSelectedMatrices);
                        setSelectedSamplingStages(initialSelectedSamplingStages);
                        setSelectedMatrixGroups(initialSelectedMatrixGroups);
                        setSelectedYear(initialSelectedYear);
                        setSelectedSuperCategory(initialSelectedSuperCategory);
                        setSearchParameters(params_1);
                        anyParamsPresent = Object.values(params_1).some(function (arr) { return arr.length > 0; });
                        if (!anyParamsPresent) return [3, 4];
                        setIsSearchTriggered(true);
                        return [4, fetchDataFromAPI({
                                microorganisms: initialSelectedMicroorganisms,
                                sampleOrigins: initialSelectedSampleOrigins,
                                matrices: initialSelectedMatrices,
                                samplingStages: initialSelectedSamplingStages,
                                matrixGroups: initialSelectedMatrixGroups,
                                years: initialSelectedYear,
                                superCategories: initialSelectedSuperCategory,
                            })];
                    case 3:
                        _a.sent();
                        return [3, 5];
                    case 4:
                        setIsSearchTriggered(false);
                        _a.label = 5;
                    case 5: return [3, 8];
                    case 6:
                        err_4 = _a.sent();
                        console.error("Error during initialization:", err_4);
                        setError("Initialization error: ".concat(err_4 instanceof Error ? err_4.message : "Unknown error"));
                        return [3, 8];
                    case 7:
                        setLoading(false);
                        return [7];
                    case 8: return [2];
                }
            });
        }); };
        initializeData();
    }, []);
    useEffect(function () {
        var updateOptionsBasedOnSelection = function () {
            var computeOptions = function (excludeFilter, getOptionValue) {
                var filtered = fullPrevalenceData.filter(function (entry) {
                    var conditions = [
                        excludeFilter !== "microorganism"
                            ? selectedMicroorganisms.length === 0 ||
                                selectedMicroorganisms.includes(entry.microorganism)
                            : true,
                        excludeFilter !== "sampleOrigin"
                            ? selectedSampleOrigins.length === 0 ||
                                selectedSampleOrigins.includes(entry.sampleOrigin)
                            : true,
                        excludeFilter !== "matrix"
                            ? selectedMatrices.length === 0 ||
                                selectedMatrices.includes(entry.matrix)
                            : true,
                        excludeFilter !== "samplingStage"
                            ? selectedSamplingStages.length === 0 ||
                                selectedSamplingStages.includes(entry.samplingStage)
                            : true,
                        excludeFilter !== "matrixGroup"
                            ? selectedMatrixGroups.length === 0 ||
                                selectedMatrixGroups.includes(entry.matrixGroup)
                            : true,
                        excludeFilter !== "superCategorySampleOrigin"
                            ? selectedSuperCategory.length === 0 ||
                                selectedSuperCategory.includes(entry.superCategorySampleOrigin)
                            : true,
                        excludeFilter !== "samplingYear"
                            ? selectedYear.length === 0 ||
                                selectedYear.includes(entry.samplingYear)
                            : true,
                    ];
                    return conditions.every(Boolean);
                });
                var names = Array.from(new Set(filtered.map(getOptionValue)))
                    .map(String)
                    .sort(function (a, b) { return a.localeCompare(b); });
                return names.map(function (name) { return ({ name: name }); });
            };
            setMicroorganismOptions(computeOptions("microorganism", function (entry) { return entry.microorganism; }));
            setSampleOriginOptions(computeOptions("sampleOrigin", function (entry) { return entry.sampleOrigin; }));
            setMatrixOptions(computeOptions("matrix", function (entry) { return entry.matrix; }));
            setSamplingStageOptions(computeOptions("samplingStage", function (entry) { return entry.samplingStage; }));
            setMatrixGroupOptions(computeOptions("matrixGroup", function (entry) { return entry.matrixGroup; }));
            setSuperCategorySampleOriginOptions(computeOptions("superCategorySampleOrigin", function (entry) { return entry.superCategorySampleOrigin; }));
            var years = computeOptions("samplingYear", function (entry) { return entry.samplingYear; })
                .map(function (option) { return parseInt(option.name, 10); })
                .sort(function (a, b) { return a - b; });
            setYearOptions(years);
        };
        updateOptionsBasedOnSelection();
    }, [
        selectedMicroorganisms,
        selectedSampleOrigins,
        selectedMatrices,
        selectedSamplingStages,
        selectedMatrixGroups,
        selectedSuperCategory,
        selectedYear,
        fullPrevalenceData,
    ]);
    var triggerSearch = function () {
        fetchDataFromAPI();
        setShowError(true);
    };
    useEffect(function () {
        fetchPrevalenceData();
        fetchOptions();
    }, [i18next.language]);
    var contextValue = {
        microorganismOptions: microorganismOptions,
        sampleOriginOptions: sampleOriginOptions,
        matrixOptions: matrixOptions,
        samplingStageOptions: samplingStageOptions,
        matrixGroupOptions: matrixGroupOptions,
        superCategorySampleOriginOptions: superCategorySampleOriginOptions,
        yearOptions: yearOptions,
        selectedMicroorganisms: selectedMicroorganisms,
        setSelectedMicroorganisms: setSelectedMicroorganisms,
        selectedSampleOrigins: selectedSampleOrigins,
        setSelectedSampleOrigins: setSelectedSampleOrigins,
        selectedMatrices: selectedMatrices,
        setSelectedMatrices: setSelectedMatrices,
        selectedSamplingStages: selectedSamplingStages,
        setSelectedSamplingStages: setSelectedSamplingStages,
        selectedMatrixGroups: selectedMatrixGroups,
        setSelectedMatrixGroups: setSelectedMatrixGroups,
        selectedYear: selectedYear,
        setSelectedYear: setSelectedYear,
        selectedSuperCategory: selectedSuperCategory,
        setSelectedSuperCategory: setSelectedSuperCategory,
        triggerSearch: triggerSearch,
        fetchDataFromAPI: fetchDataFromAPI,
        fetchOptions: fetchOptions,
        setIsSearchTriggered: setIsSearchTriggered,
        prevalenceData: isSearchTriggered ? prevalenceData : [],
        error: error,
        loading: loading,
        searchParameters: searchParameters,
        showError: showError,
        setShowError: setShowError,
        isSearchTriggered: isSearchTriggered,
    };
    return (React.createElement(DefaultPrevalenceDataContext.Provider, { value: contextValue }, children));
};
