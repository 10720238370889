import React from "react";
import { Box, Button } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { errorBarTooltipPlugin, drawErrorBars, whiteBackgroundAndLogoPlugin, } from "./chartPlugins";
import { useTranslation } from "react-i18next";
import { formatMicroorganismNameArray } from "./utils";
var generateColorFromKey = function (key) {
    var hash = 0;
    for (var i = 0; i < key.length; i++) {
        hash = key.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = "hsl(".concat(hash % 360, ", 70%, 60%)");
    return color;
};
var customTitlePlugin = function (microName) { return ({
    id: "customTitle",
    beforeDraw: function (chart) {
        var ctx = chart.ctx, chartArea = chart.chartArea, width = chart.width;
        if (!microName)
            return;
        var formattedWords = formatMicroorganismNameArray(microName);
        var titleYPosition = chartArea.top - 60;
        ctx.save();
        ctx.textAlign = "left";
        ctx.fillStyle = "black";
        var xPosition = (width -
            formattedWords.reduce(function (acc, _a) {
                var text = _a.text, italic = _a.italic;
                ctx.font = italic
                    ? "italic bold 21px Arial"
                    : "bold 21px Arial";
                return acc + ctx.measureText(text).width + 1;
            }, 0)) /
            2;
        formattedWords.forEach(function (_a) {
            var text = _a.text, italic = _a.italic;
            ctx.font = italic ? "italic bold 21px Arial" : "bold 21px Arial";
            ctx.fillText(text, xPosition, titleYPosition);
            xPosition += ctx.measureText(text).width + 1;
        });
        ctx.restore();
    },
}); };
var ChartCard = function (_a) {
    var chartKey = _a.chartKey, chartData = _a.chartData, chartRef = _a.chartRef, currentMicroorganism = _a.currentMicroorganism, yearOptions = _a.yearOptions, xAxisMax = _a.xAxisMax, downloadChart = _a.downloadChart, prevalenceUpdateDate = _a.prevalenceUpdateDate;
    var t = useTranslation(["PrevalencePage"]).t;
    var chartColor = generateColorFromKey(chartKey);
    return (React.createElement(Box, { sx: {
            backgroundColor: "white",
            height: "620px",
            padding: 5,
            paddingBottom: 8,
            borderRadius: 2,
            boxShadow: 2,
            margin: "0 5px",
            position: "relative",
        } },
        React.createElement(Bar, { data: {
                labels: yearOptions,
                datasets: [
                    {
                        label: chartKey,
                        data: yearOptions.map(function (year) {
                            return chartData[year] || {
                                x: 0,
                                y: year,
                                ciMin: 0,
                                ciMax: 0,
                            };
                        }),
                        backgroundColor: chartColor,
                    },
                ],
            }, options: {
                indexAxis: "y",
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 40,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: t("Prevalence %"),
                            color: "black",
                            font: {
                                size: 17,
                                weight: "bold",
                            },
                        },
                        beginAtZero: true,
                        max: xAxisMax,
                        ticks: {
                            color: "black",
                            font: {
                                size: 14,
                            },
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: t("Year"),
                            color: "black",
                            font: {
                                size: 17,
                                weight: "bold",
                            },
                        },
                        reverse: false,
                        ticks: {
                            color: "black",
                            font: {
                                size: 13,
                            },
                            callback: function (_, index) {
                                return yearOptions[index];
                            },
                        },
                    },
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        labels: {
                            color: "black",
                            font: {
                                size: 14,
                            },
                        },
                        position: "top",
                    },
                    tooltip: {
                        backgroundColor: "rgba(0, 0, 0, 1)",
                        titleFont: {
                            size: 14,
                        },
                        bodyFont: {
                            size: 12,
                        },
                        displayColors: true,
                        borderColor: "#fff",
                        borderWidth: 2,
                        caretPadding: 120,
                        yAlign: "center",
                        callbacks: {
                            label: function (context) {
                                var year = parseInt(context.label || "", 10);
                                var data = chartData[year] || {};
                                var rawData = context.raw;
                                return [
                                    "".concat(t("Prevalence"), ": ").concat(rawData.x, "%"),
                                    "".concat(t("CI_min"), ": ").concat(data.ciMin),
                                    "".concat(t("CI_max"), ": ").concat(data.ciMax),
                                    "".concat(t("Samples"), ": ").concat(data.numberOfSamples),
                                    "".concat(t("Positive"), ": ").concat(data.numberOfPositive),
                                ];
                            },
                        },
                    },
                    customTexts: {
                        generatedOn: t("Generated on"),
                    },
                },
                animation: false,
            }, plugins: [
                errorBarTooltipPlugin,
                customTitlePlugin(currentMicroorganism),
                {
                    id: "customErrorBars",
                    afterDraw: function (chart) { return drawErrorBars(chart); },
                },
                whiteBackgroundAndLogoPlugin(prevalenceUpdateDate),
            ], ref: chartRef }),
        React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
            } },
            React.createElement(Button, { variant: "contained", size: "medium", onClick: function () { return downloadChart(chartRef, chartKey); }, sx: {
                    textTransform: "none",
                } }, t("Download_Chart")))));
};
export { ChartCard };
