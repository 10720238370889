import React from "react";
import { Link, List, ListItem, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
export function FooterLinkListComponent(props) {
    var _a;
    var t = useTranslation(["Footer"]).t;
    var theme = useTheme();
    var linkStyle = {
        width: "100%",
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        justifyContent: "center",
        "&:focus": {
            outline: "none",
        },
    };
    var disableLinkStyle = {
        width: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        color: "gray",
    };
    var footerElementStyle = {
        width: "fit-content",
        padding: "0.5em",
        flex: "1 1 auto",
        listStyleType: "none",
        cursor: "pointer",
        color: theme.palette.primary.main,
        transition: "0.3s",
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    };
    var footerContentStyle = (_a = {
            margin: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexGrow: 1,
            padding: 0
        },
        _a[theme.breakpoints.down("sm")] = {
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "nowrap",
            "& .MuiListItem-root": {
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
            },
        },
        _a);
    var submitProblemLink = (React.createElement(Link, { href: "mailto:".concat(props.supportMail, "?subject=ZooNotify-Problem"), sx: linkStyle },
        React.createElement(Typography, null, t("Content.Mail"))));
    if (!props.supportMail) {
        var supportMailErrorText = t("Content.SupportError");
        submitProblemLink = (React.createElement(Tooltip, { title: supportMailErrorText, placement: "top", sx: {
                backgroundColor: "transparent",
                color: theme.palette.error.main,
            } },
            React.createElement(Box, { sx: disableLinkStyle },
                React.createElement(Typography, null, t("Content.Mail")))));
    }
    return (React.createElement(List, { sx: footerContentStyle },
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(Link, { href: "https://www.bfr.bund.de/en/home.html", sx: linkStyle },
                React.createElement(Typography, null, t("Content.Bfr")))),
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(Link, { href: "https://foodrisklabs.bfr.bund.de/foodrisk-labs/", sx: linkStyle },
                React.createElement(Typography, null, "FoodRisk-Labs"))),
        React.createElement(ListItem, { sx: footerElementStyle },
            React.createElement(NavLink, { to: "/dpd", style: linkStyle },
                React.createElement(Typography, null, t("Content.DataProtection")))),
        React.createElement(ListItem, { sx: footerElementStyle }, submitProblemLink)));
}
