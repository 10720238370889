import React from "react";
import { Route, Switch } from "react-router-dom";
import { EvaluationsMainComponent } from "../../../evaluations/pages/EvaluationsMainComponent";
import { InfoPageContainer } from "../../../explanation/pages/InfoPage-Container.component";
import { LinkedDataComponent } from "../../../ld/pages/LinkedDataComponent";
import { DataProtectionPageComponent } from "../../../pages/data_protection/DataProtectionPage.component";
import { ErrorPageComponent } from "../../../pages/error/ErrorPage.component";
import { LinkPageComponent } from "../../../pages/links/LinkPage.component";
import { PrevalenceMainComponent } from "../../../prevalence/pages/PrevalenceMainComponent";
import { WelcomeMainComponent } from "../../../welcome/pages/WelcomeMainComponent";
import { AntibioticResistancePageComponent } from "../../../antibiotic_resistance/pages/AntibioticResistancePage.component";
import { pageRoute } from "./routes";
function ErrorPage() {
    return React.createElement(ErrorPageComponent, { errorStatus: 404 });
}
export function BodyRouterComponent() {
    var showLD = process.env.REACT_APP_SHOW_LD === "true";
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: pageRoute.homePagePath, component: WelcomeMainComponent }),
        React.createElement(Route, { path: pageRoute.infoPagePath, component: InfoPageContainer }),
        React.createElement(Route, { path: pageRoute.evaluationsPagePath, component: EvaluationsMainComponent }),
        React.createElement(Route, { path: pageRoute.prevalencePagePath, component: PrevalenceMainComponent }),
        React.createElement(Route, { path: pageRoute.antibioticResistancePagePath, component: AntibioticResistancePageComponent }),
        React.createElement(Route, { path: pageRoute.linkPagePath, component: LinkPageComponent }),
        React.createElement(Route, { path: pageRoute.dpdPagePath, component: DataProtectionPageComponent }),
        showLD && (React.createElement(Route, { path: pageRoute.linkedDataPagePath, component: LinkedDataComponent })),
        React.createElement(Route, { component: ErrorPage })));
}
