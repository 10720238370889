var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from "@emotion/react";
import { useState } from "react";
import { FooterContainer } from "../footer/Footer-Container.component";
import { HeaderComponent } from "../header/Header.component";
import { ErrorSnackbar } from "../ErrorSnackbar/ErrorSnackbar";
var layoutWrapperStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    z-index: 0;\n    min-height: 100vh;\n    box-sizing: border-box;\n"], ["\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    z-index: 0;\n    min-height: 100vh;\n    box-sizing: border-box;\n"])));
var headerStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    z-index: 1;\n"], ["\n    z-index: 1;\n"])));
var mainStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    flex: 1;\n    z-index: 0;\n    overflow-x: hidden;\n    overflow-y: hidden;\n    box-sizing: border-box;\n\n    @media (max-width: 1000px) {\n        overflow-y: auto;\n        -webkit-overflow-scrolling: touch;\n    }\n"], ["\n    flex: 1;\n    z-index: 0;\n    overflow-x: hidden;\n    overflow-y: hidden;\n    box-sizing: border-box;\n\n    @media (max-width: 1000px) {\n        overflow-y: auto;\n        -webkit-overflow-scrolling: touch;\n    }\n"])));
var footerStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    z-index: 1;\n"], ["\n    z-index: 1;\n"])));
export var PageLayoutComponent = function (_a) {
    var children = _a.children;
    var _b = __read(useState(false), 2), snackbarOpen = _b[0], setSnackbarOpen = _b[1];
    var handleSnackbarClose = function () {
        setSnackbarOpen(false);
    };
    return (_jsxs("div", __assign({ css: layoutWrapperStyle }, { children: [_jsx("header", __assign({ css: headerStyle }, { children: _jsx(HeaderComponent, {}) })), _jsx("main", __assign({ css: mainStyle }, { children: children })), _jsx("footer", __assign({ css: footerStyle }, { children: _jsx(FooterContainer, {}) })), _jsx(ErrorSnackbar, { open: snackbarOpen, handleClose: handleSnackbarClose })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
