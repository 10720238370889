var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Stack, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grow, } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Search from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { FilterMultiSelectionComponent } from "../../evaluations/components/FilterMultiSelectionComponent";
import { usePrevalenceFilters } from "./PrevalenceDataContext";
import { callApiService } from "../../shared/infrastructure/api/callApi.service";
import i18next from "i18next";
import { INFORMATION } from "../../shared/infrastructure/router/routes";
import Markdown from "markdown-to-jsx";
export function PrevalenceSideContent() {
    var _this = this;
    var _a = useTranslation(["PrevalencePage"]), t = _a.t, i18n = _a.i18n;
    var _b = usePrevalenceFilters(), selectedMicroorganisms = _b.selectedMicroorganisms, setSelectedMicroorganisms = _b.setSelectedMicroorganisms, microorganismOptions = _b.microorganismOptions, selectedSampleOrigins = _b.selectedSampleOrigins, setSelectedSampleOrigins = _b.setSelectedSampleOrigins, sampleOriginOptions = _b.sampleOriginOptions, selectedMatrices = _b.selectedMatrices, setSelectedMatrices = _b.setSelectedMatrices, matrixOptions = _b.matrixOptions, selectedSamplingStages = _b.selectedSamplingStages, setSelectedSamplingStages = _b.setSelectedSamplingStages, samplingStageOptions = _b.samplingStageOptions, selectedMatrixGroups = _b.selectedMatrixGroups, setSelectedMatrixGroups = _b.setSelectedMatrixGroups, matrixGroupOptions = _b.matrixGroupOptions, selectedYear = _b.selectedYear, setSelectedYear = _b.setSelectedYear, yearOptions = _b.yearOptions, selectedSuperCategory = _b.selectedSuperCategory, setSelectedSuperCategory = _b.setSelectedSuperCategory, superCategorySampleOriginOptions = _b.superCategorySampleOriginOptions, fetchDataFromAPI = _b.fetchDataFromAPI, setShowError = _b.setShowError, fetchOptions = _b.fetchOptions, setIsSearchTriggered = _b.setIsSearchTriggered;
    var _c = __read(useState(false), 2), infoDialogOpen = _c[0], setInfoDialogOpen = _c[1];
    var _d = __read(useState(""), 2), infoDialogTitle = _d[0], setInfoDialogTitle = _d[1];
    var _e = __read(useState(""), 2), infoDialogContent = _e[0], setInfoDialogContent = _e[1];
    var _f = __read(useState([]), 2), selectedOrder = _f[0], setSelectedOrder = _f[1];
    useEffect(function () {
        setSelectedOrder([]);
    }, []);
    useEffect(function () {
        var handleLanguageChange = function () {
            fetchOptions();
        };
        i18n.on("languageChanged", handleLanguageChange);
        return function () {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, [i18n, fetchOptions]);
    var handleInfoClick = function (categoryKey) { return __awaiter(_this, void 0, void 0, function () {
        var translatedCategory, url, response, attributes, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    translatedCategory = t(categoryKey);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = "".concat(INFORMATION, "?filters[title][$eq]=").concat(encodeURIComponent(translatedCategory), "&locale=").concat(i18next.language, "&pagination[pageSize]=1");
                    return [4, callApiService(url)];
                case 2:
                    response = _a.sent();
                    if (response.data && response.data.data.length > 0) {
                        attributes = response.data.data[0].attributes;
                        setInfoDialogTitle(attributes.title);
                        setInfoDialogContent(attributes.content);
                        setInfoDialogOpen(true);
                    }
                    return [3, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to fetch information:", error_1);
                    return [3, 4];
                case 4: return [2];
            }
        });
    }); };
    var handleClose = function () {
        setInfoDialogOpen(false);
    };
    var updateFilterOrder = function (filter) {
        setSelectedOrder(function (prevOrder) {
            if (!prevOrder.includes(filter)) {
                return __spreadArray(__spreadArray([], __read(prevOrder), false), [filter], false);
            }
            return prevOrder;
        });
    };
    var handleSearch = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setShowError(false);
                    return [4, fetchDataFromAPI()];
                case 1:
                    _a.sent();
                    setShowError(true);
                    return [2];
            }
        });
    }); };
    var filterComponents = {
        year: (React.createElement(Box, { key: "year", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedYear.map(String), selectionOptions: yearOptions.map(function (option) { return ({
                    value: option.toString(),
                    displayName: option.toString(),
                }); }), name: "years", label: t("SAMPLING_YEAR"), actions: {
                    handleChange: function (event) {
                        var valueAsNumbers = event.target.value.map(function (val) { return parseInt(val, 10); });
                        setSelectedYear(valueAsNumbers);
                        updateFilterOrder("year");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Sampling Year") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("SAMPLING_YEAR"); }, sx: { marginLeft: 0.2 } },
                    React.createElement(InfoIcon, null))))),
        microorganism: (React.createElement(Box, { key: "microorganism", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedMicroorganisms, selectionOptions: microorganismOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "microorganisms", label: t("MICROORGANISM"), actions: {
                    handleChange: function (event) {
                        setSelectedMicroorganisms(event.target.value);
                        updateFilterOrder("microorganism");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Microorganisms") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("MICROORGANISM"); }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
        superCategory: (React.createElement(Box, { key: "superCategory", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedSuperCategory, selectionOptions: superCategorySampleOriginOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "superCategories", label: t("SUPER-CATEGORY-SAMPLE-ORIGIN"), actions: {
                    handleChange: function (event) {
                        setSelectedSuperCategory(event.target.value);
                        updateFilterOrder("superCategory");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Super Categories") },
                React.createElement(IconButton, { onClick: function () {
                        return handleInfoClick("SUPER-CATEGORY-SAMPLE-ORIGIN");
                    }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
        sampleOrigin: (React.createElement(Box, { key: "sampleOrigin", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedSampleOrigins, selectionOptions: sampleOriginOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "sampleOrigins", label: t("SAMPLE_ORIGIN"), actions: {
                    handleChange: function (event) {
                        setSelectedSampleOrigins(event.target.value);
                        updateFilterOrder("sampleOrigin");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Sample Origins") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("SAMPLE_ORIGIN"); }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
        samplingStage: (React.createElement(Box, { key: "samplingStage", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedSamplingStages, selectionOptions: samplingStageOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "samplingStages", label: t("SAMPLING_STAGE"), actions: {
                    handleChange: function (event) {
                        setSelectedSamplingStages(event.target.value);
                        updateFilterOrder("samplingStage");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Sampling Stages") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("SAMPLING_STAGE"); }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
        matrixGroup: (React.createElement(Box, { key: "matrixGroup", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedMatrixGroups, selectionOptions: matrixGroupOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "matrixGroups", label: t("MATRIX_GROUP"), actions: {
                    handleChange: function (event) {
                        setSelectedMatrixGroups(event.target.value);
                        updateFilterOrder("matrixGroup");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Matrix Groups") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("MATRIX_GROUP"); }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
        matrix: (React.createElement(Box, { key: "matrix", sx: {
                display: "flex",
                alignItems: "center",
                width: "100%",
            } },
            React.createElement(FilterMultiSelectionComponent, { selectedItems: selectedMatrices, selectionOptions: matrixOptions.map(function (option) { return ({
                    value: option.name,
                    displayName: option.name,
                }); }), name: "matrices", label: t("MATRIX"), actions: {
                    handleChange: function (event) {
                        setSelectedMatrices(event.target.value);
                        updateFilterOrder("matrix");
                    },
                } }),
            React.createElement(Tooltip, { title: t("More Info on Matrices") },
                React.createElement(IconButton, { onClick: function () { return handleInfoClick("MATRIX"); }, sx: { marginLeft: 0.5 } },
                    React.createElement(InfoIcon, null))))),
    };
    var getTransitionIn = function () {
        return true;
    };
    var orderedComponents = selectedOrder.map(function (key) { return (React.createElement(Grow, { in: getTransitionIn(), timeout: 500, key: key }, filterComponents[key])); });
    var remainingComponents = Object.keys(filterComponents)
        .filter(function (key) { return !selectedOrder.includes(key); })
        .map(function (key) { return (React.createElement(Grow, { in: getTransitionIn(), timeout: 500, key: key }, filterComponents[key])); });
    var resetFilters = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSelectedMicroorganisms([]);
                    setSelectedSampleOrigins([]);
                    setSelectedMatrices([]);
                    setSelectedSamplingStages([]);
                    setSelectedMatrixGroups([]);
                    setSelectedYear([]);
                    setSelectedSuperCategory([]);
                    setSelectedOrder([]);
                    setIsSearchTriggered(false);
                    setShowError(false);
                    return [4, fetchOptions()];
                case 1:
                    _a.sent();
                    window.history.replaceState(null, "", window.location.pathname);
                    return [2];
            }
        });
    }); };
    return (React.createElement(Box, { sx: {
            padding: "10px",
            height: "80vh",
            overflowY: "auto",
            width: "410px",
            maxHeight: "calc(140vh)",
            maxWidth: "95%",
        } },
        React.createElement(Stack, { spacing: 0.5, alignItems: "flex-start" },
            orderedComponents,
            remainingComponents),
        React.createElement(Dialog, { open: infoDialogOpen, onClose: handleClose },
            React.createElement(DialogTitle, null, infoDialogTitle),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null,
                    React.createElement(Markdown, null, infoDialogContent))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose }, "Close"))),
        React.createElement(Box, { sx: {
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                gap: "16px",
            } },
            React.createElement(Button, { variant: "contained", startIcon: React.createElement(Search, null), onClick: handleSearch }, t("SEARCH")),
            React.createElement(Button, { variant: "contained", onClick: resetFilters }, t("RESET_FILTERS")))));
}
